import Image from 'next/image'
import Link from 'next/link'
import SectionHeading from '../Common/SectionHeading'
import { useEffect, useState } from 'react';

export default function ReportSection ({data=null}) {
  const [deviceType, setDeviceType] = useState('desktop')

  useEffect(()=>{
    setDeviceType(localStorage.getItem('device'))
  },[])

  return (
    <>
      {(data) &&
        <>
          <div className='flex flex-col justify-center w-11/12 mx-auto my-6 xl:max-w-screen max-w-screen-2xl lg:my-10'>
            <div className="flex overflow-x-auto lg:overflow-hidden" style={{WebkitOverflowScrolling : 'touch'}}>
              <span className="pr-4 text-lg font-semibold">Analysis from <br/>Recent Conference Coverage</span>
              <ul className="lg:w-full w-[1200px] flex lg:flex-row justify-between">
                <li className="lg:w-full w-[360px] flex lg:py-0">
                  <span className="text-2xl mr-1 text-[#1b5df3]">1</span>
                  <div className="px-2 border-l border-blue-500">
                    <Link href={process.env.APP_URL+'/eha-conference/eha-2024/myelofibrosis-preview-content-eha-2024'}>
                      Spotlight On Myelofibrosis: Key Preview Highlights From EHA 2024 Congress
                    </Link>
                  </div>
                </li>
                <li className="lg:w-full w-[360px] flex lg:py-0">
                  <span className="text-2xl mr-1 text-[#1b5df3]">2</span>
                  <div className="px-2 border-l border-blue-500">
                    <Link href={process.env.APP_URL+'/eha-conference/eha-2024/epkinly-phase-ib-ii-epcore-nhl-5-study'}>
                      High Overall And Complete Response Rates With Epcoritamab And Pola-R-CHP In Newly Diagnosed DLBCL: A Promising Safety Profile
                    </Link>
                  </div>
                </li>
                <li className="lg:w-full w-[360px] flex lg:py-0">
                  <span className="text-2xl mr-1 text-[#1b5df3]">3</span>
                  <div className="px-2 border-l border-blue-500">
                    <Link href={process.env.APP_URL+'/eha-conference/eha-2024/pociredir-phase-ib-study-pioneer'}>
                      Interim Results Of A Phase Ib Study (PIONEER) Of An Oral HBF Inducer, Pociredir, In Sickle Cell Disease
                    </Link>
                  </div>
                </li>
                <li className="lg:w-full w-[360px] flex lg:py-0">
                  <span className="text-2xl mr-1 text-[#1b5df3]">4</span>
                  <div className="px-2 border-l border-blue-500">
                    <Link href={process.env.APP_URL+'/eha-conference/eha-2024/phase-ib-trial-results-of-disc-0974'}>
                      A Phase Ib Trial Of DISC-0974, An Anti-Hemojuvelin Antibody, In Patients With Myelofibrosis And Anemia
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='flex flex-col justify-center w-11/12 mx-auto my-6 xl:max-w-screen max-w-screen-2xl lg:my-10'>
            <div className="grid grid-cols-12 gap-4">
              {data.content.map((item, index) => (
                <div key={index} className="col-span-12 lg:col-span-3">
                  <SectionHeading
                    title={item.title}
                    customClass='lg:text-left'
                  />
                  <p className="text-gray-800 dark:text-white">{item.description}</p>
                </div>
                ))}
                {data.items.map((item, index) => (
                  <div key={index} className={index === 2 ? "col-span-12 lg:col-span-3" : "col-span-6 lg:col-span-3"} >
                    <Link href={process.env.APP_URL+item.link}>
                      <div className="">
                        <Image priority={false}
                          src={process.env.IMG_CDN+item.imageSrc}
                          alt={item.altText ? item.altText : 'DelveInsight'}
                          width={2000}
                          height={2000}
                          className="w-full h-full rounded hover:opacity-75"
                        />
                      </div>
                    </Link>
                  </div>
                ))}
                <div className="col-span-12 md:col-span-6">
                  <div className="h-full">
                  {data.content.map((item, index) => (
                    <div key={index} className="h-full p-5 bg-gray-100 border-b-2 border-blue-500 rounded lg:p-6 dark:bg-gray-700">
                      <SectionHeading
                        title={item.shortTitle}
                        customClass='text-left'
                      />
                      <p className="text-gray-800 dark:text-white md:line-clamp-2 lg:line-clamp-none">{item.shortDescription}</p>
                      <Link href={process.env.APP_URL+"/report-store.php"}>
                        <button className="btn btn-primary lg:mt-6" role="button">{item.buttonTitle}</button>
                      </Link>
                    </div>
                    ))}
                  </div>
                </div>
            </div>
          </div>
        </>
      }
    </>
  );
}